export const reasonsList: string[] = [
  'Merry Christmas Grammie! To celebrate your 80 amazing years on planet earth, here\'s 80 reasons I love you.',
  'You are caring',
  'You started my Roth IRA',
  'You are compassionate',
  'You babysat me as a kid',
  'Everyone loves you',
  'You married the perfect husband',
  'You respect others',
  'You\'re good at math',
  'You participate in homemakers',
  'You\’re still rocking it at 80',
  'You are encouraging',
  'You have great Christmas traditions',
  'You\’ve documented our family’s history',
  'You are sincere',
  'You are dedicated',
  'You are selfless',
  'You tell great family stories',
  'You take good care of Indy and gave him a home',
  'You\'ve supported me',
  'You run Grammie Camp',
  'You smuggled sheep’s wool out of iceland',
  'You are well respected by everyone',
  'You make the best stew',
  'You brought me to Texas',
  'You run down the street after our car when we leave',
  'You\’ve raised an amazing family',
  'You welcome missionaries into your home to stay',
  'You got me my first tools',
  'You are patient',
  'You always have good snacks stocked up',
  'You walked to the top of a waterfall in Iceland',
  'You took the family to a dude ranch in Colorado',
  'You are organized',
  'You help organize family reunions',
  'You raised 4 incredible children',
  'You created a warm and comfortable home',
  'You support my interests',
  'You have a cool collection of old historical items',
  'You attended all of my sporting events',
  'You’re fun to play board games with',
  'You have an amazing backyard garden',
  'You have strong faith',
  'You make amazing Chex mix',
  'Our barefoot runs in the snow :)',
  'You\'re funny',
  'You\’re willing to share a lifetime of knowledge and wisdom',
  'You never stops learning and growing',
  'You accidentally screamed “jackass” during catch phrase',
  'You are a careful and safe driver',
  'You are loving',
  'You are genuine',
  'You took me to family camp',
  'You never lose touch with friends and relatives',
  'You\’re always honest',
  'You are dilligent',
  'You throw rolls like a boss',
  'You are wise',
  'Car pretzels',
  'You are generous',
  'You are creative',
  'You are determined',
  'You helped me get through college',
  'You\’re always prepared and ahead of schedule',
  'You took me on my first airplane ride',
  'You are empathetic',
  'You live a life worth modeling after',
  'You\’re fun to play Catch Phrase with',
  'You\’ve lived in many different states',
  'You\’re incredibly smart',
  'You have an amazing memory',
  'You taught me how to backpack',
  'You\’re a great conversationalist',
  'You’re amazing at bananagrams',
  'You took me on road trips',
  'You make the best wassle',
  'You are supportive',
  'You are adventurous',
  'You make the best chicken divan',
  'You give great financial advice',
  'You are genuinely interested and involved in my life',
];
