<div class="toolbar" role="banner">
 Merry Christmas Grammie
</div>

<div class="content" role="main">

    <swiper class="swiper-container" [config]="config">
      <div *ngFor="let slide of slides" class="swiper-slide">
        <div style="padding: 30px;">
          {{slide}}
        </div>
      </div>
    </swiper>
</div>